import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";

import useLocalUserData from "../../localStorage/useLocalUserData";
import useCurrentUser from "../../redux/control/useCurrentUser";
import useDelayedStateToggle from "../../hooks/useDelayStateToggle";

import BackDrop from "../ui/backdrop/BackDrop";
import ToolTip from "../ui/tooltip/ToolTip";

import { closeBtnIcon } from "../../assets/icons";
import TotalCredits from "../credits/TotalCredits";
import UseCredits from "../credits/UseCredits";

import useRecordActivity from "../../hooks/useRecordActivity";
import useActivityRecordControl from "../../redux/control/useActivitySymbolize";

import HistoryTrackRecordDash from "../../functions/HistoryTrackRecordDash";
import DataPopupNavbar from "./DataPopupNavbar";
import useCredit from "../../redux/control/useCredit";
import { set } from "js-cookie";
import ChaptersDropNav from "./ChaptersDropNav";

const nioLogo = "./nioLogo.svg";
// const CLLogo = "./CLLogo.svg"
import { CLLogo } from "../../assets/icons";
import { highSchool } from "../landingpage/Landingpage";
import { loggedInUserCheck } from "../signIn/SignIn";
import useSigninPop from "../../redux/control/useSignInPop";

const pathsToCheck = [
  "dashboard",
  "chapter-selection",
  "chapter-practice",
  "testSeries",
  "timerTest",
  "submitTest",
];

export const activatedPath = pathsToCheck.some((path) =>
  window.location.pathname.includes(path)
);

// export const activatedPath =
//   window.location.pathname.includes("dashboard") ||
//   window.location.pathname.includes("chapter-selection") ||
//   window.location.pathname.includes("chapter-practice") ||
//   window.location.pathname.includes("testSeries") ||
//   window.location.pathname.includes("timerTest") ||
//   window.location.pathname.includes("submitTest");
// console.log(activatedPath);
function AuthenticatedNavLinks(props) {
  const {
    auth,
    userid,
    user,
    setUser,
    handleClickLogo,
    subjectualData,
    setOpenNameModal,
    startingAnimation,
    bounceAnimation,
    setBounceAnimation,
    utmCompaignD,
    openNameModal,
  } = props;

  const { userCredit, setUsersCredit } = useCredit();
  const { currentSigninPopup, setloginPopVisible } = useSigninPop();

  const useridal = auth.currentUser?.phoneNumber
    ? auth.currentUser.phoneNumber?.substring(
        1,
        auth.currentUser.phoneNumber?.length
      )
    : '"' + auth.currentUser?.email + '"';
  // useEffect(() => {
  //   if (
  //     useridal != '"undefined"' &&
  //     useridal &&
  //     user === '"undefined"' &&
  //     !(user && user !== '"undefined"')
  //   ) {
  //     setUser(useridal);
  //     // console.log(useridal);
  //   }
  // }, [useridal]);
  // console.log(auth?.currentUser?.phoneNumber, userid);
  const recordActivity = useRecordActivity(
    // auth?.currentUser?.phoneNumber ? auth?.currentUser?.phoneNumber :
    userid
  );
  let navbtns = subjectualData?.navbar;
  let org = subjectualData?.org?.toLowerCase();
  // console.log(window.location.pathname)

  const navigate = useNavigate();

  const { unAuthenticateLocalUser } = useLocalUserData();
  const { removeCurrentUser } = useCurrentUser();
  // const endingAnimation = useRef()

  const [displayName, setDisplayUserName] = useState("NioUser");
  const [activeTab, setActiveTab] = useState("dashboard");
  const [isOpen, setIsopen] = useState(false);
  const [UserCredits, setUserCredits] = useState(0);
  const [openCreditScore, setOpenCreditScore] = useState(false);
  const [variableChangeAnimation, setvariableChangeAnimation] = useState(false);
  const [useCredits, setUseCredits] = useState(false);
  const [profilePopUpVisible, setProfilePopUpVisible] = useState(false);

  const [isProfileIconHover, setIsProfileIconHover] = useState(false);
  const [showMoreLoadIcon, setShowLoadMoreIcon] = useState(false);
  const [dropDownEnable, setDropDownEnable] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [allSubjects, setAllSubjects] = useState(subjectualData?.DropSubjects);
  useEffect(() => {
    if (subjectualData?.DropSubjects) {
      setAllSubjects(subjectualData.DropSubjects);
    }
  }, [subjectualData?.DropSubjects]);
  const { currentActivityStepRecord, setActivityModalStep } =
    useActivityRecordControl();
  // console.log(currentActivityStepRecord)

  const [toggleProfileIconHover, toggleProfileIconHoverFalse] =
    useDelayedStateToggle(setIsProfileIconHover);

  const ToggleSidebar = () => {
    setIsopen(!isOpen);
  };

  const handleLogout = () => {
    signOut(auth);
    setIsopen(false);
    unAuthenticateLocalUser();
    setUser(null);
    removeCurrentUser();
    navigate("/website");
    localStorage.removeItem("UserID");
  };

  // function handleChangeToNavigateDefault() {
  //     navigate("/website");
  // }

  const handleCreditBtn = (e) => {
    setProfilePopUpVisible(false);
    setIsopen(false);
    setOpenCreditScore(true);

    const targetName = e.target.name;
    recordActivity("click", targetName, "navbar");
  };

  async function getUserCredits() {
    // if (userCredit === -1) {
    await axios
      .get(
        `https://sqa1zros3i.execute-api.ap-northeast-1.amazonaws.com/getUserCredits/getusercredits?UserID=${userid}`
      )
      .then((res) => {
        // console.log(res)
        setUsersCredit(res.data);
        // setUserCredits(res.data);
        // //console.log(res.data);
      });
  }
  // }
  //   console.log(userid);
  function getUserDetails() {
    axios
      .get(
        `https://uhuu9h6pef.execute-api.ap-northeast-1.amazonaws.com/getUserProfileDetails/getuserprofiledetails?UserID=${userid}`
      )
      .then((res) => {
        // console.log(res.data[0]?.NAME);
        if (
          res.data[0]?.NAME?.length > 0 &&
          res.data[0]?.NAME &&
          subjectualData?.org?.toLowerCase() != "cl"
        ) {
          setDisplayUserName(res.data[0].NAME);
          setOpenNameModal(false);
        } else {
          setDisplayUserName("NioUser");
          if (subjectualData?.org?.toLowerCase() != "cl") {
            setOpenNameModal(true);
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const handleNavClick = (destinationPath, e, Chapter = "") => {
    if (
      !loggedInUserCheck(userid) &&
      (location.pathname.includes("website") ||
        location.pathname.includes("contact-us")) &&
      destinationPath !== "/contact-us"
    ) {
      setloginPopVisible(true);
      return;
    }
    setProfilePopUpVisible(false);
    if (Chapter) {
      navigate(destinationPath + "?&" + Chapter + "&Test=yes", {
        state: { Chapter: Chapter, UserID: userid },
      });
      setDropDownEnable("");
    } else {
      navigate(destinationPath, {
        state: { UserID: userid },
      });
      if (destinationPath === "/contact-us") {
        return;
      }
    }

    if (!loggedInUserCheck(userid)) {
      // console.log(activatedPath);
      if (
        !pathsToCheck.some((path) => window.location.pathname.includes(path))
      ) {
        // if (destinationPath === "/contact-us") {
        //   setloginPopVisible(false);
        // } else {
        setloginPopVisible(true);
        // }
      } else {
        setloginPopVisible(false);
      }
    }
    setIsopen(false);
    // console.log(Chapter)

    setIsProfileIconHover(false);

    const targetName = e.target.name;
    recordActivity("click", targetName, "navbar");
  };
  // console.log(window.location.pathname);
  const handleCloseCredit = () => {
    setOpenCreditScore(false);
    setUseCredits(false);
  };
  let ExamType = subjectualData?.SlugCh;

  useMemo(() => {
    async function HistoryTrackRecordDashDeatils() {
      try {
        if (userid) {
          // console.log()

          // console.log(userid)
          // const res = await HistoryTrackRecordDash(userid, 15, pageHistoryRecordDash * 15, (chapter[0] ? chapter[0].ChapterID : ""))
          const res = await HistoryTrackRecordDash(userid, 7, 0, "", ExamType);
          // console.log(res, questionHistory.length, pageHistoryRecordDash, questionHistory)
          const records = res.filter(
            (obj1) => !(obj1.status === "non attempt")
          );
          // console.log(res.length, records.length, (res.length >= 15 && records.length >= 1))
          setShowLoadMoreIcon(records.length >= 3 ? true : false);
          // console.log((records.length >= 3) ? true : false, records.length)
          // if (questionHistory.length < 15) {
          //     // console.log(1)
          //     setQuestionHistory(res)
          //     setInitiatedChaptersLoaded(true);
          //     // setShowLoadMoreIcon(false)

          // }
          // else {
          //     // if(loadmoreClicked){
          //     // console.log(2)

          //     setQuestionHistory([...questionHistory, ...records])
          //     setInitiatedChaptersLoaded(true);
          //     // setPageHistoryRecordDash(pageHistoryRecordDash + 1)

          // }
          // // }
        }
      } catch (err) {
        console.log("%cError- ", "color: red", err);
      }
    }
    HistoryTrackRecordDashDeatils();
    getUserCredits();
  }, [userid, currentActivityStepRecord]);

  useEffect(() => {
    getUserCredits();

    // setTimeout(() => {
    // if (displayName === 'NioUser') {
    // }
    // }, [2000])
  }, [userid, displayName, showMoreLoadIcon]);
  useEffect(() => {
    if (location.pathname === "/activity-record") {
      setActiveTab("MyActivity");
      // console.log("hii")
    } else if (location.pathname === "/chapter-selection") {
      setActiveTab("ChaptersSelection");
    } else if (location.pathname === "/myNotes") {
      setActiveTab("myNotes");
    } else if (location.pathname === "/testSeries") {
      setActiveTab("testSeries");
    } else if (location.pathname === "/dashboard") {
      setActiveTab("dashboard");
    } else if (location.pathname === "/contact-us") {
      setActiveTab("contactus");
    } else {
      setActiveTab("none");
    }
  }, [location.pathname]);
  // console.log(user);
  useEffect(() => {
    setTimeout(() => {
      if (
        (userid != '"undefined"' && userid) ||
        loggedInUserCheck(localStorage.getItem("UserID"))
      ) {
        // console.log(userid);
        if (loggedInUserCheck(userid) || loggedInUserCheck(user)) {
          getUserDetails();
        }
        getUserCredits();
      }
    }, [2000]);
  }, [
    userid,
    userid?.length,
    useridal,
    localStorage.getItem("UserID"),
    user,
    openNameModal,
  ]);
  // console.log(subjectualData);

  // useEffect(() => {
  //     axios.get("https://ryw9bvqib6.execute-api.ap-northeast-1.amazonaws.com/ExamsSaturations/").then(res => {
  //         // console.log(res.data)
  //         let adata = res.data
  //         setAllSubjects(adata.DropSubjects)
  //     })
  // }, [])
  // useEffect(() => {
  //     setvariableChangeAnimation(startingAnimation.current ? (startingAnimation.current.getBoundingClientRect()) : "")
  // }, [startingAnimation.current])
  // useEffect(() => {
  //     // Access the position using getBoundingClientRect
  //     console.log(startingAnimation.current)
  //     if (startingAnimation.current != undefined) {
  //         const { top, left } = startingAnimation.current.getBoundingClientRect();
  //         const { topa, lefts } = endingAnimation.current.getBoundingClientRect();

  //         console.log('Object position in Component B:', startingAnimation.current.getBoundingClientRect(), endingAnimation.current.getBoundingClientRect());
  //         console.log('Object position in Component B:', top, left, topa, lefts, variableChangeAnimation);
  //     }
  // }, [startingAnimation.current, auth, currentActivityStepRecord, setActivityModalStep, variableChangeAnimation]);
  useEffect(() => {
    if (window?.location?.host?.toLowerCase()?.includes("careerlauncher"))
      if (!document.referrer?.toLowerCase()?.includes("careerlauncher")) {
        localStorage.removeItem("UserID");
        localStorage.removeItem("Token");

        window.location.href =
          "https://myzone.careerlauncher.com/mba/sis/test.jsp";
      }
  }, []);

  const redirectToURL = () => {
    localStorage.removeItem("UserID");
    localStorage.removeItem("Token");

    window.location.href = "https://myzone.careerlauncher.com/mba/sis/test.jsp";
  };

  return (
    <div className="header-container">
      {openCreditScore && (
        <>
          <TotalCredits
            userid={userid}
            UserCredits={userCredit}
            setUseCredits={setUseCredits}
            setOpenCreditScore={setOpenCreditScore}
          />
          <BackDrop closeHandler={handleCloseCredit} blur />
        </>
      )}

      {useCredits && (
        <>
          <UseCredits
            userid={userid}
            UserCredits={userCredit}
            setUseCredits={setUseCredits}
          />
          <BackDrop closeHandler={handleCloseCredit} blur />
        </>
      )}

      <button
        className="header__left header__site-logo header__left__fitWidth"
        // onClick={handleClickLogo}
        onClick={(e) =>
          subjectualData?.navbar?.includes("dashboard")
            ? handleNavClick("/dashboard", e)
            : handleNavClick(subjectualData?.navbar[0], e)
        }
        name="btn-site-logo"
      >
        <img
          className="siteIcon"
          alt="nioclass"
          // src={nioLogo}
          src={
            subjectualData?.logo
              ? subjectualData?.logo === "nio"
                ? nioLogo
                : subjectualData?.logo
              : nioLogo
          }
        />
        {/* <span className="header__part header__site-name">practice</span> */}
      </button>

      <div className="header__right">
        <nav className="navbar__main">
          {navbtns?.includes("mynotes") ? (
            <button
              className="navbar_link"
              name="link-myNotes"
              onClick={(e) => handleNavClick("/myNotes", e)}
              style={{
                color:
                  activeTab === "myNotes" ? "var(--accent-primary-clr)" : "",
              }}
            >
              My Notes
              <div className="navbar__slider__menu-itemUnderline"></div>
            </button>
          ) : null}
          {navbtns?.includes("testSeries") ? (
            <button
              className="navbar_link"
              name="link-myNotes"
              onClick={(e) => handleNavClick("/testSeries", e)}
              style={{
                color:
                  activeTab === "testSeries" ? "var(--accent-primary-clr)" : "",
              }}
            >
              Test Series
              <div className="navbar__slider__menu-itemUnderline"></div>
            </button>
          ) : null}
          {navbtns?.includes("mymemory") ? (
            <>
              <button
                ref={startingAnimation}
                className={`navbar_link ${
                  !showMoreLoadIcon ? "showMoreLoadIcon" : ""
                } ${bounceAnimation && "bouncingAnimation"}`}
                name="link-activity-record"
                onClick={(e) =>
                  showMoreLoadIcon
                    ? handleNavClick("/activity-record", e)
                    : alert("Practice atleast 3 Questions to unlock this")
                }
                style={{
                  color:
                    activeTab === "MyActivity"
                      ? "var(--accent-primary-clr)"
                      : "",
                }}
                onAnimationEnd={() => setBounceAnimation(false)}
              >
                {/* {displayName === "NioUser"
                  ? "My Dashboard"
                  : displayName + "'s Dashboard"} */}
                My Memory
                <div className="navbar__slider__menu-itemUnderline"></div>
                <div
                  className={`absoluteActivityRecordIncrement ${
                    currentActivityStepRecord >= 1
                      ? currentActivityStepRecord % 2 === 1
                        ? "initial"
                        : "again"
                      : "hiddenState"
                  }`}
                >
                  +1
                </div>
              </button>
            </>
          ) : null}

          {/* <button className="navbar_link" name="link-dashboard"
                        onClick={(e) => handleNavClick("/dashboard", e)}
                    >
                        {(displayName === "NioUser") ? "My Dashboard" : (displayName + "'s Dashboard")}
                    </button> */}
          {navbtns?.includes("dashboard") ? (
            <button
              className="navbar_link"
              name="link-myNotes"
              onClick={(e) => handleNavClick("/dashboard", e)}
              style={{
                color:
                  activeTab === "dashboard" ? "var(--accent-primary-clr)" : "",
              }}
            >
              {displayName === "NioUser"
                ? "My Dashboard"
                : displayName + "'s Dashboard"}
              {/* My Memory */}
              <div className="navbar__slider__menu-itemUnderline"></div>
            </button>
          ) : null}
          {navbtns?.includes("contactus") ? (
            <button
              className="navbar_link"
              name="link-myNotes"
              onClick={(e) => handleNavClick("/contact-us", e)}
              style={{
                color:
                  activeTab === "contactus" ? "var(--accent-primary-clr)" : "",
              }}
            >
              Contact us
              <div className="navbar__slider__menu-itemUnderline"></div>
            </button>
          ) : null}
          {navbtns?.includes("chapters") ? (
            <div
              data-testid="ChapterSelecctionnavbar"
              onMouseEnter={() => setDropDownEnable("Chapters")}
              onClick={() => setDropDownEnable("Chapters")}
              onMouseLeave={() => setDropDownEnable("")}
              className="deskTopOnly"
            >
              <button
                className="navbar_link"
                name="link-chapter-selection"
                // onClick={(e) => handleNavClick("/chapter-selection", e)}
                style={{
                  color:
                    activeTab === "ChaptersSelection"
                      ? "var(--accent-primary-clr)"
                      : "",
                }}
              >
                <div className="dropdownlinker">
                  Chapters{" "}
                  <div
                    className="rotationanimate"
                    style={{
                      transform:
                        dropDownEnable === "Chapters"
                          ? "rotate(180deg)"
                          : "rotate(360deg)",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.48 7.39098L0 1L12.48 22L24 1L12.48 7.39098Z"
                        fill="var(--text-primary-clr)"
                      />
                    </svg>
                  </div>
                </div>
                <div className="navbar__slider__menu-itemUnderline"></div>
              </button>
              <div
                style={{
                  position: "absolute",
                  height: "4.5em",
                  width: "5em",
                  marginTop: "-2em",
                  background: "transparent",
                  opacity: 0,
                }}
              ></div>
              {dropDownEnable === "Chapters" ? (
                <ChaptersDropNav
                  allSubjects={allSubjects}
                  setAllSubjects={setAllSubjects}
                  setSelectedSubject={setSelectedSubject}
                  selectedSubject={selectedSubject}
                  handleNavClick={handleNavClick}
                />
              ) : (
                ""
              )}
            </div>
          ) : null}

          {/* <button
                        className="navbar_link" name="link-contact-us"
                        onClick={(e) => handleNavClick("/contact-us", e)}
                    >
                        Contact Us
                    </button> */}

          {/* <button className="navbar_link">
                        <div className="navLinks" name="btn-credit"
                            onClick={handleCreditBtn}
                        >
                            Credits :{" "}
                            <span style={{ color: "var(--accent-primary-clr)" }}>
                                {UserCredits}{" "}
                            </span>
                        </div>
                    </button> */}

          {navbtns?.includes("profilebtn") ? (
            <>
              {loggedInUserCheck(userid) ? (
                <button
                  className="profile__pic-container hide-small"
                  name="btn-start-practicing"
                  onMouseOver={toggleProfileIconHover}
                  onMouseOut={toggleProfileIconHoverFalse}
                  // onClick={(e) => handleNavClick("/my-profile", e)}
                  onClick={(e) => setProfilePopUpVisible(!profilePopUpVisible)}
                >
                  <div className="profile__pic">{displayName[0]}</div>
                  {isProfileIconHover && <ToolTip left text="Profile" />}
                </button>
              ) : null}
            </>
          ) : null}
          {loggedInUserCheck(userid) ? (
            navbtns?.includes("logout") ? (
              <button
                className="navbar_link"
                name="link-myNotes"
                onClick={handleLogout}
                style={{
                  color: "var(--bg-senary-clr-0)",
                  opacity: "0.9",
                  fontSize: "12px",
                }}
              >
                Logout
                <div className="navbar__slider__menu-itemUnderline"></div>
              </button>
            ) : null
          ) : (
            <>
              <button
                className="app-btn-1"
                onClick={() => setloginPopVisible(true)}
              >
                Login
              </button>
            </>
          )}
          {navbtns?.includes("exitbtn") ? (
            <button
              className="navbar_link"
              style={{
                padding: "0.3em",
                paddingInline: "1em",
                color: "#FF0000",
                // fontSize: "24px",
                border: "2px solid #3B366E",
                borderRadius: "30px",
                display: "flex",
                gap: "0.5em",
                alignItems: "center",
              }}
              onClick={redirectToURL}
            >
              EXIT
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 1.00101H1V15.001C1 15.5314 1.21071 16.0401 1.58579 16.4152C1.96086 16.7903 2.46957 17.001 3 17.001H11M12 12.001L15 9.00101M15 9.00101L12 6.00101M15 9.00101H5"
                  stroke="#FF0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          ) : null}
          <div
            className={`popupdevNavbar ${
              profilePopUpVisible ? "show" : "hiddens"
            }`}
          >
            <DataPopupNavbar
              UserCredits={userCredit}
              handleCreditBtn={handleCreditBtn}
              handleNavClick={handleNavClick}
              name={displayName}
              phoneNumber={userid}
            />
          </div>
          {profilePopUpVisible && (
            <BackDrop
              closeHandler={() => setProfilePopUpVisible(false)}
              blur
              noHeader
            />
          )}
        </nav>

        <button
          className="header__menu-btn"
          name="btn-menu"
          onClick={ToggleSidebar}
        >
          ☰
        </button>
      </div>

      {/* {isOpen ? */}
      {isOpen ? (
        <nav className={`navbar__slider ${isOpen ? "show" : ""} `}>
          <div className="navbar__slider__menu-container">
            <div>
              <div>
                <button
                  className="navbar__slider__close"
                  name="btn-menu"
                  onClick={ToggleSidebar}
                >
                  <img src={closeBtnIcon} alt="" />
                </button>
              </div>

              {navbtns?.includes("profilebtn") ? (
                <button
                  className="navbar__slider__user-info-container"
                  name="btn-profile"
                  // onClick={(e) => handleNavClick("/my-profile", e)}
                >
                  <div className="profile__pic-container">
                    <div className="profile__pic">{displayName[0]}</div> {}
                  </div>

                  <h1 className="display-name"> {displayName} </h1>
                </button>
              ) : null}

              {navbtns?.includes("dashboard") ? (
                <button
                  className="navbar__slider__menu-item"
                  name="link-dashboard"
                  onClick={(e) => handleNavClick("/dashboard", e)}
                >
                  {displayName === "NioUser"
                    ? "My Dashboard"
                    : displayName + "'s Dashboard"}
                </button>
              ) : null}

              {navbtns?.includes("testSeries") ? (
                highSchool ? (
                  window.innerWidth > 600 ? (
                    <button
                      className="navbar__slider__menu-item"
                      name="lin-chapter-selection"
                      onClick={(e) => handleNavClick("/testSeries", e)}
                    >
                      Test Series
                    </button>
                  ) : (
                    ""
                  )
                ) : (
                  <button
                    className="navbar__slider__menu-item"
                    name="lin-chapter-selection"
                    onClick={(e) => handleNavClick("/testSeries", e)}
                  >
                    Test Series
                  </button>
                )
              ) : (
                ""
              )}
              {navbtns?.includes("chapters") ? (
                <button
                  className="navbar__slider__menu-item"
                  name="lin-chapter-selection"
                  onClick={(e) =>
                    setDropDownEnable((prev) => (prev === "" ? "Chapters" : ""))
                  }
                >
                  <div className="dropdownlinker">
                    Chapters{" "}
                    <div
                      className="rotationanimate"
                      style={{
                        transform:
                          dropDownEnable === "Chapters"
                            ? "rotate(180deg)"
                            : "rotate(360deg)",
                      }}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.48 7.39098L0 1L12.48 22L24 1L12.48 7.39098Z"
                          fill="var(--text-primary-clr)"
                        />
                      </svg>
                    </div>
                  </div>
                </button>
              ) : null}
              {dropDownEnable === "Chapters" ? (
                <>
                  {subjectualData.DropSubjects.map((obj, idx) => (
                    <button
                      key={idx}
                      className="navbar__slider__menu-item dropDownTopics"
                      name="lin-chapter-selection"
                      onClick={(e) =>
                        handleNavClick("/chapter-selection", e, obj)
                      }
                    >
                      {obj}
                    </button>
                  ))}
                  {/* <button
                    className="navbar__slider__menu-item dropDownTopics"
                    name="lin-chapter-selection"
                    onClick={(e) =>
                      handleNavClick("/chapter-selection", e, "Physics")
                    }
                  >
                    Physics
                  </button>
                  <button
                    className="navbar__slider__menu-item dropDownTopics"
                    name="lin-chapter-selection"
                    onClick={(e) =>
                      handleNavClick("/chapter-selection", e, "Chemistry")
                    }
                  >
                    Chemistry
                  </button> */}
                </>
              ) : (
                ""
              )}

              {org === "cl" ? (
                <button
                  className="navbar__slider__menu-item"
                  name="link-contact-us"
                  onClick={(e) => handleNavClick("/myNotes", e)}
                >
                  My Notes
                </button>
              ) : (
                <button
                  className="navbar__slider__menu-item"
                  name="link-contact-us"
                  onClick={(e) => handleNavClick("/contact-us", e)}
                >
                  Contact Us
                </button>
              )}

              {org === "cl" ? (
                <button
                  className="navbar__slider__menu-item"
                  name="link-contact-us"
                  onClick={(e) =>
                    showMoreLoadIcon
                      ? handleNavClick("/activity-record", e)
                      : alert("Practice atleast 3 Questions to unlock this")
                  }
                >
                  My Memory
                </button>
              ) : navbtns?.includes("profilebtn") ? (
                <button className="navbar__slider__menu-item" name="btn-credit">
                  <div
                    className="navLinks"
                    name="btn-credit"
                    onClick={handleCreditBtn}
                  >
                    Credits :{" "}
                    <span style={{ color: "var(--accent-primary-clr)" }}>
                      {userCredit === -1 ? 0 : userCredit}{" "}
                    </span>
                  </div>
                </button>
              ) : null}
            </div>
            <div className="navbar__slider__btn-container">
              {navbtns?.includes("exitbtn") ? (
                <button
                  className="navbar_link"
                  style={{
                    padding: "0.3em",
                    paddingInline: "1em",
                    color: "#FF0000",
                    // fontSize: "24px",
                    border: "2px solid #3B366E",
                    borderRadius: "30px",
                    display: "flex",
                    gap: "0.5em",
                    alignItems: "center",
                  }}
                  onClick={redirectToURL}
                >
                  EXIT
                  <svg
                    width="16"
                    height="18"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1.00101H1V15.001C1 15.5314 1.21071 16.0401 1.58579 16.4152C1.96086 16.7903 2.46957 17.001 3 17.001H11M12 12.001L15 9.00101M15 9.00101L12 6.00101M15 9.00101H5"
                      stroke="#FF0000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              ) : loggedInUserCheck(userid) ? (
                <button
                  className="navbar__slider__menu-item log-out-btn"
                  name="btn-logout"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              ) : null}
            </div>
          </div>
        </nav>
      ) : (
        ""
      )}
      {/* : ""} */}

      {isOpen && <BackDrop closeHandler={() => setIsopen(false)} />}
    </div>
  );
}

export default AuthenticatedNavLinks;
