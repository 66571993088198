import PropTypes from "prop-types";
import "./FrameComponent.css";

const FrameComponent = ({ className = "" }) => {
let testiMonialData=[
  {
    text:`Custom Test feature is the best! Isne meri preparation ko bohot easy
    bana diya! Ab mujhe apne weak topics ya kisi bhi topic ko practice
    karne ke liye PDF dhoondhne ki zarurat nahi padti. Ab main sirf
    subject aur chapter select karke ek quick mini mock bana kar
    practice kar sakti hoon. It has really helped me improve my speed
    and accuracy. Sabse achi baat ye lagi ke saare questions previous
    year papers se hote hain.`,
    Name:"Shalini Jain",
    Exam:"SSC CGL"
  },
  {
    text:`I'm preparing for SSC CGL and I was looking especially for custom
    tests for CGL, I think NioPractice is the one who provides it. Thank
    you for providing such amazing mock tests. The questions of mock
    tests were very well curated. I'll recommend NioPractice to all the
    aspirants who are preparing for any SSC exam and try out their
    Custom Test feature it's amazing.`,
    Name:"Ramesh Gupta",
    Exam:"SSC CGL"
  },
  {
    text:`I can say this is something new and better for SSC CGL students.
    NioPractices Custom Test feature is incredible! It has made my
    preparation so much easier. I no longer need to search for PDFs to
    practice any topics. I can simply select the subject and chapter to
    create a quick mini mock test. This has significantly improved my
    confidence and marks as well. The best part is that all the
    questions are with solution`,
    Name:"Kailash Chaurasia",
    Exam:"SSC CGL"
  },
]
if(window?.location?.host?.toLowerCase()?.includes("rrb")){
  testiMonialData=[
    {
      text: `Custom Test feature is the best! Isne meri RRB NTPC preparation ko bohot easy bana diya! Ab mujhe apne weak topics ya kisi bhi topic ko practice karne ke liye PDF dhoondhne ki zarurat nahi padti. Ab main sirf subject aur chapter select karke ek quick mini mock bana kar practice kar sakti hoon. It has really helped me improve my speed and accuracy. Sabse achi baat ye lagi ke saare questions previous year papers se hote hain.`,
      Name: "Khushi Arora",
      Exam: "RRB NTPC"
    },
    {
      text: `I'm preparing for RRB NTPC and I was looking especially for custom tests for NTPC; I think NioPractice is the one who provides it. Thank you for providing such amazing mock tests. The questions of mock tests were very well curated. I'll recommend NioPractice to all the aspirants who are preparing for any railway exam and try out their Custom Test feature—it's amazing.`,
      Name: "Manoj Bansal",
      Exam: "RRB NTPC"
    },
    {
      text: `I can say this is something new and better for RRB NTPC students. NioPractice's Custom Test feature is incredible! It has made my preparation so much easier. I no longer need to search for PDFs to practice any topics. I can simply select the subject and chapter to create a quick mini mock test. This has significantly improved my confidence and marks as well. The best part is that all the questions come with solutions.`,
      Name: "Rakesh Yadav",
      Exam: "RRB NTPC"
    }
]

}
if(window?.location?.host?.toLowerCase()?.includes("ibps")){
  testiMonialData=[
      {
        text: `Custom Test feature is the best! Isne meri Bank PO preparation ko bohot easy bana diya! Ab mujhe apne weak topics ya kisi bhi topic ko practice karne ke liye PDF dhoondhne ki zarurat nahi padti. Ab main sirf subject aur chapter select karke ek quick mini mock bana kar practice kar sakti hoon. It has really helped me improve my speed and accuracy. Sabse achi baat ye lagi ke saare questions previous year papers se hote hain.`,
        Name: "Jaya Sharma",
        Exam: "IBPS PO"
      },
      {
        text: `I'm preparing for IBPS PO and I was looking especially for custom tests for IBPS, I think NioPractice is the one who provides it. Thank you for providing such amazing mock tests. The questions of mock tests were very well curated. I'll recommend NioPractice to all the aspirants who are preparing for any Bank PO exam and try out their Custom Test feature—it's amazing.`,
        Name: "Naveen Gupta",
        Exam: "IBPS PO"
      },
      {
        text: `I can say this is something new and better for IBPS PO students. NioPractice's Custom Test feature is incredible! It has made my preparation so much easier. I no longer need to search for PDFs to practice any topics. I can simply select the subject and chapter to create a quick mini mock test. This has significantly improved my confidence and marks as well. The best part is that all the questions come with solutions.`,
        Name: "Harish Yadav",
        Exam: "IBPS PO"
      }
  ]
  

}
  return (
    <div className={`testimonials-section ${className}`}>
      <div className="heading3">
        <img className="review-icon" alt="" src="/review-icon.svg" />
        <div className="what-students-say-about-us-parent">
          <b className="what-students-say">What students say about us</b>
          <img className="seperator-icon" alt="" src="/seperator.svg" />
        </div>
      </div>
      <div className="testimonials SSCTest">
        <div className="testimonial-1">
          <div className="jorem-ipsum-dolor1">
          {testiMonialData[0]?.text}
          </div>
          <div className="user-details">
            <img
              className="user-details-child"
              alt=""
              src="/ellipse-600@2x.png"
            />
            <div className="shalini-jain">{testiMonialData[0]?.Name}</div>
            <div className="ssc-mts">{testiMonialData[0]?.Exam}</div>
          </div>
        </div>
        <div className="testimonial-1">
          <div className="jorem-ipsum-dolor1">
            {testiMonialData[1]?.text}
          </div>
          <div className="user-details">
            <img
              className="user-details-child"
              alt=""
              src="/ellipse-601@2x.png"
            />
            <div className="shalini-jain">{testiMonialData[1]?.Name}</div>
            <div className="ssc-mts">{testiMonialData[1]?.Exam}</div>
          </div>
        </div>
        <div className="testimonial-1">
          <div className="jorem-ipsum-dolor1">
          {testiMonialData[2]?.text}
          </div>
          <div className="user-details">
            <img
              className="user-details-child"
              alt=""
              src="/ellipse-602@2x.png"
            />
            <div className="shalini-jain">{testiMonialData[2]?.Name}</div>
            <div className="ssc-mts">{testiMonialData[2]?.Exam}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
