import PropTypes from "prop-types";
import "./Section2.css";

const Section2 = ({ className = "" }) => {
  return (
    <div className={`test-analysis ${className}`}>
      <div className="test-analysis-features">
        <div className="heading2">
          <img className="heading-child" alt="" src={
            "/frame-96615275.svg"
            
          
           } />
          <b className="personalized-test-analysis-container">
            <p className="personalized-test-analysis">
              Personalized Test Analysis
            </p>
            <p className="personalized-test-analysis">{`& Leaderboard`}</p>
          </b>
        </div>
        <div className="features2">
          <div className="parent4">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Get detailed analysis of your attempted tests
            </div>
          </div>
          <div className="parent5">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Find where you stand in leaderboard
            </div>
          </div>
          <div className="parent5">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Unlimited Test Re-attempts
            </div>
          </div>
          <div className="parent5">
            <div className="div7">•</div>
            <div className="get-detailed-analysis">
              Re-attempt the test to enhance your performence
            </div>
          </div>
        </div>
      </div>
      <div className="test-analysis-image">
        <img
          className="test-analysis-image1"
          alt=""
          src={
          window.location.host?.toLowerCase()?.includes("ssc")?
          "/dashbord-image@2x.png":
          window.location.host?.toLowerCase()?.includes("ibps")?
          "/IBPS_Personalisa.png":
          "/rrb_personali.png"
        }
        />
      </div>
    </div>
  );
};

Section2.propTypes = {
  className: PropTypes.string,
};

export default Section2;
